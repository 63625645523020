import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminUsersRoutingModule } from './admin-users-routing.module';
import { AdminUserPageComponent } from './admin-user-page/admin-user-page.component';
import { AdminUserTableComponent } from './admin-user-table/admin-user-table.component';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [AdminUserTableComponent, AdminUserPageComponent],
  imports: [SharedModule, AdminUsersRoutingModule, CommonModule],
})
export class AdminUsersModule {}
