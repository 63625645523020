import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Upload, UploadData, UploadRequest, UploadResponse } from '../models/upload';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient) { }

  public async uploadFile(data: UploadRequest, file: File): Promise<UploadResponse> {
    const uploadData = await this.http
      .post<UploadData>('uploads', data)
      .toPromise();

    const formData = new FormData();

    Object
      .entries(uploadData.data.fields)
      .forEach(([key, value]) => formData.append(key, value));

    formData.append('file', file);

    console.table(Object.entries(uploadData.data.fields))

    const resp = await this.http
      .post<UploadResponse>(uploadData.data.url, formData)
      .toPromise();
    return resp;
  }

  public async getUploadByKey(key: string): Promise<Upload> {
    return this.http.get<Upload>('uploads', { params: { key } }).toPromise();
  }
}
