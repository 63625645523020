import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-unblock-user-dialog',
  templateUrl: './unblock-user-dialog.component.html',
  styleUrls: ['./unblock-user-dialog.component.scss']
})
export class UnblockUserDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<UnblockUserDialogComponent>,
    protected router: Router,
    @Inject(MAT_DIALOG_DATA) public data: { blockedUserId: string, blockedUserName: string, blockerUserName: string },
  ) { }

  public onGoToUser(userId: string) {
    this.dialogRef.close(null);
    return this.router.navigateByUrl(`/users/${ userId }`);
  }
}
