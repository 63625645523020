import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-follow-request-dialog',
  templateUrl: './follow-request-dialog.component.html',
  styleUrls: ['./follow-request-dialog.component.scss']
})
export class FollowRequestDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<FollowRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { requesterName: string, requestedName: string },
  ) { }
}
