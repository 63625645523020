import { BaseModel } from 'src/app/shared/models/base-model';

export enum AdminType {
	superadmin = 'superadmin',
	admin = 'admin',
	// Add other admin roles
}

export enum AdminStatus {
	active = 'active',
	suspended = 'suspended',
	pending = 'pending',
}

export interface Admin extends BaseModel {
	firstName: string;
	lastName: string;
	email: string;
	password?: string;
	status: AdminStatus;
	type: AdminType;
}

export type AllowedRoles = '*' | AdminType[];
