import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { catchError, mergeMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { TimeUtils } from "src/app/shared/utils/time.utils";

@Injectable({
  providedIn: 'root',
})
export class JwtInterceptor implements HttpInterceptor {
  constructor(public auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth
      .token$
      .pipe(
        take(1),
        mergeMap((token) => {
          let headers = req.headers
            .append('Prly-User-Agent', `web 1 prly-admin/${ environment.version }/webapp`)
            .append('Prly-Timezone', TimeUtils.timezone);

          if (token) headers = headers.append('Authorization', `Bearer ${ token }`);

          const url = needsPrefix(req.url)
            ? `${environment.apiBaseUrl}/${req.url}`
            : req.url;

          return next.handle(req.clone({ url, headers }));
        }),
        catchError((err) => {
          const { status } = err;
          if (status === 401) void this.auth.clearLocalStorage();

          throw err;
        })
      );
  }
}

function needsPrefix(url: string) {
  return !url.startsWith('/assets')
    && !url.startsWith('https://')
    && !url.startsWith('http://');
}
