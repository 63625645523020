import { Pipe, PipeTransform } from '@angular/core';
import { ProfilePictureType } from '../models/misc';

@Pipe({
  name: 'profilePicture'
})
export class ProfilePicturePipe implements PipeTransform {

  transform(url: undefined | string | null, type: ProfilePictureType): string {
    return !url?.length
      ? (() => {
        switch (type) {
          case ProfilePictureType.user: return '/assets/images/user-placeholder.png';
          case ProfilePictureType.footballClub: return '/assets/images/logo-prly.svg'
          default: throw new Error('Invalid profile picture type');
        }
      })()
      : url;
  }

}
