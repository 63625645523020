<h2 mat-dialog-title>Unblock user</h2>
<div mat-dialog-content>
    <p>Unblock <b>{{ data.blockedUserName }}</b> on behalf of <b>{{ data.blockerUserName }}</b> ? <b>{{ data.blockedUserName }}</b> will be visible from now on in <b>{{ data.blockerUserName }}</b>'s search results and also the two can now follow each other again.</p>
</div>
<div mat-dialog-actions style="display:flex;justify-content:flex-end;gap:32px;margin-top:16px;margin-bottom:-16px;">
    <span style="cursor:pointer;user-select:none;margin-right:auto;padding-left:0;text-align:left;"
          class="hoverEffect"
          (click)="onGoToUser(data.blockedUserId)"
    >See <b>{{ data.blockedUserName }}</b>'s profile</span>

    <button mat-icon-button
            class="btn-primary"
            (click)="dialogRef.close(false)"
            matTooltip="Cancel">

        <i class="icon">close</i>
    </button>
    <button mat-icon-button
            class="btn-primary"
            (click)="dialogRef.close(true)"
            matTooltip="Unblock user">

        <i class="icon">lock_open</i>
    </button>
</div>
