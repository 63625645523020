import { FootballTag } from "./football/tag";
import { FootballTopic } from './football/topic';
import { ReservedNickname } from "src/app/shared/models/reserved-nickname";

export enum FormType {
	add = 'add',
	edit = 'edit',
}

export namespace Args {
  interface BaseFormComponent {
    type: FormType;
  }
	export interface TagFormComponent extends BaseFormComponent {
		tag?: FootballTag;
	}

	export interface TopicFormComponent extends BaseFormComponent {
		topic?: FootballTopic;
	}

  export interface ReservedNicknameFormComponent extends BaseFormComponent {
    reservedNickname?: ReservedNickname;
  }
}

export enum ProfilePictureType {
	user = 'user',
	footballClub = 'footballClub',
}
