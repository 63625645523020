import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { Admin } from '../models/admin';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private token: string;
  private tokenSubject: Subject<string> = new ReplaySubject(1);
  private userSubject: BehaviorSubject<Admin> = new BehaviorSubject(null);

  public get token$(): Observable<string> { return this.tokenSubject.asObservable(); }
  public get user$(): Observable<Admin> { return this.userSubject.asObservable(); }
  public get user(): Admin { return this.userSubject.value; }

  constructor(private router: Router, private http: HttpClient) {
    this.getDataFromStorage();
  }


  public async login(email: string, password: string, rememberMe = true) {
    if (!email || !password) {
      throw new Error('Invalid email or password');
    }

    const resp = await this.http
      .post<{ admin: Admin; authToken: string }>('auth', { email, password })
      .toPromise();
    this.handleLoginResponse(resp, rememberMe);
  }

  public async logout() {
    await this.http.delete('auth', { }).toPromise();
    this.clearLocalStorage();
  }

  public async clearLocalStorage() {
    sessionStorage.clear();
    localStorage.clear();
    this.tokenSubject.next(null);
    this.userSubject.next(null);
    this.token = null;
    void this.router.navigate(['/login']);
  }

  public isLoggedIn() {
    return !!this.token;
  }

  private handleLoginResponse(resp: { admin: Admin; authToken: string }, rememberMe = true) {
    const { admin, authToken } = resp;

    if (rememberMe) {
      localStorage.setItem('authToken', authToken);
      localStorage.setItem('admin', JSON.stringify(admin));
    }

    sessionStorage.setItem('authToken', authToken);
    sessionStorage.setItem('admin', JSON.stringify(admin));

    this.token = authToken;
    this.tokenSubject.next(this.token);
    this.userSubject.next(admin);
  }

  private getDataFromStorage() {
    const token = sessionStorage.getItem('authToken') || localStorage.getItem('authToken');
    const user = JSON.parse(sessionStorage.getItem('admin') || localStorage.getItem('admin'));
    this.tokenSubject.next(token || null);
    this.token = token;
    this.userSubject.next(user);
  }
}
