<header class="page-header">
    <app-back route="/admin/admin-users"></app-back>
    <h1 *ngIf="mode === 'edit'">Update admin user</h1>
    <h1 *ngIf="mode === 'add'">Add admin user</h1>
</header>

<form #userForm="ngForm" (ngSubmit)="onSubmit()" [appLoading]="isLoading" class="card columns-1 gap">

    <div class="row">

        <div class="form-group">
            <label for="email">First name</label>
            <input
                    [(ngModel)]="user.firstName"
                    type="text"
                    placeholder="First name *"
                    autocomplete="off"
                    id="firstName"
                    name="firstName"
                    required
            />
            <app-input-error>First name is invalid.</app-input-error>
        </div>

        <div class="form-group">
            <label for="email">Last name</label>
            <input
                    [(ngModel)]="user.lastName"
                    type="text"
                    placeholder="Last name"
                    autocomplete="off"
                    id="lastName"
                    name="lastName"
            />
            <app-input-error>Last name is invalid.</app-input-error>
        </div>

    </div>
    <div class="row">
        <div class="form-group">
            <label for="email">Email</label>
            <input
                    [(ngModel)]="user.email"
                    type="email"
                    required
                    email
                    placeholder="Email..."
                    autocomplete="off"
                    id="email"
                    name="email"
            />
            <app-input-error>Email is invalid.</app-input-error>
        </div>

        <div class="form-group">
            <label for="password">Password</label>
            <input
                    [(ngModel)]="user.password"
                    type="password"
                    placeholder="{{ mode === 'edit' ? 'Only fill this in to change it' : 'Password' }}"
                    id="password"
                    name="password"
                    autocomplete="new-password"
            />
        </div>
    </div>
    <div class="row">
        <div class="form-group">
            <label>Status</label>
            <ng-select placeholder="Change status"
                       [(ngModel)]="user.status"
                       [items]="userStatuses"
                       name="status"
                       required>
            </ng-select>
            <app-input-error>Status is invalid.</app-input-error>
        </div>

        <div class="form-group">
            <label>Role</label>
            <ng-select placeholder="Change role"
                       [(ngModel)]="user.type"
                       [items]="adminTypes"
                       name="type"
                       required>
            </ng-select>
            <app-input-error>role is invalid.</app-input-error>
        </div>
    </div>
    <app-error [error]="error"></app-error>

    <div class="actions">
        <button mat-flat-button class="btn-primary" type="submit" [disabled]="!userForm.form.valid"
                [appLoading]="isLoading">
            <ng-container *ngIf="mode === 'edit'">Update</ng-container>
            <ng-container *ngIf="mode === 'add'">Save</ng-container>
        </button>
    </div>
</form>
