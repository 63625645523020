import { User } from '../models/user';

export class TextUtils {

	public static capitalize(text: string, allWords: boolean = false) {
		const capitalizeWord = (word: string) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
		return allWords
			? text.split(/\s+/gmi).map(capitalizeWord).join(' ')
			: capitalizeWord(text);
	}

	public static textHumanize(value: string) {
		return (value || '')
			.replace(/([A-Z])/g, ' $1')
			.replace(/^./, (str) => str.toUpperCase());
	}

	public static userNameDisplay({ firstName, lastName, nickname, isFullNameDisplayed }: User) {
		return isFullNameDisplayed
			? this.capitalize(`${ firstName } ${ lastName }`, true)
			: nickname;
	}

  public static escapeCsvItem(value?: string): string {
    if ('undefined' === value) {
      return '';
    }
    if (value.includes(',')) {
      value = value.replace('"', '""');
      return `"${value}"`;
    }
    return value;
  }

  // Used when exporting users' email addresses
  public static escapeCsvItemEx(value?: string): string {
    if ('undefined' === value) return 'Not set';
    if ('null' === value) return 'Not set';

    // Boolean values
    if (value.toLowerCase() === 'true') return 'YES';
    if (value.toLowerCase() === 'false') return 'NO';

    if (value.includes(',')) {
      value = value.replace('"', '""');
      return `"${value}"`;
    }

    return value;
  }
}
