import { Component, Input, OnInit } from '@angular/core';
import { Admin, AdminStatus, AdminType } from 'src/app/shared/models/admin';
import { TextUtils } from 'src/app/shared/utils/text.utils';
import { UserStatus } from '../../../shared/models/user';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AdminUserService } from '../../../shared/services/admin-user.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'app-admin-user-page',
  templateUrl: './admin-user-page.component.html',
  styleUrls: ['./admin-user-page.component.scss'],
})
export class AdminUserPageComponent implements OnInit {
  error: any;
  userStatuses = Object.values(UserStatus)
    .map((item) => ({ label: TextUtils.capitalize(item), value: item }));
  adminTypes = Object.values(AdminType).map((item) => (({ label: TextUtils.capitalize(item), value: item })));
  mode: 'add' | 'edit' = 'edit';

  @Input()
  isLoading = false;

  @Input()
  user: Admin = {
    firstName: '',
    lastName: '',
    email: '',
    status: AdminStatus.pending,
    type: AdminType.admin,
  };

  constructor(
    private route: ActivatedRoute,
    private adminUserService: AdminUserService,
    private router: Router,
    private toast: ToastService
  ) {}

  ngOnInit() {
    this.route
      .params
      .pipe(untilDestroyed(this))
      .subscribe(async (params) => {
        console.log({ id: params.id });
        if (params.id === 'add') {
          this.mode = 'add';
        } else {
          this.mode = 'edit';
          this.user = await this.adminUserService.findOne(params.id);
          console.log(this.user);
        }
      });
  }

  async onSubmit() {
    try {
      this.error = null;
      this.isLoading = true;

      switch (this.mode) {
        case 'edit':
          this.user = await this.adminUserService.updateOne(this.user._id, this.user);
          console.log(this.user);
          await this.toast.show('Admin updated');
          break;
        case 'add':
          await this.adminUserService.addOne(this.user);
          await this.toast.show('Admin created');
          break;
      }

      await this.router.navigate(['/admin/admin-users']);
    } catch (error) {
      this.error = error;
      console.log('user', this.user)
    } finally {
      this.isLoading = false;
    }
  }
}
