import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AdminType, AllowedRoles } from 'src/app/shared/models/admin';
import { AuthService } from 'src/app/shared/services/auth.service';
import { ToastService } from '../../shared/services/toast.service';
import { environment } from "src/environments/environment";

export interface MenuItem {
  title: string;
  link?: string;
  icon?: string;
  submenu?: { title: string; link: string }[];
  expanded?: boolean;
  roles: AllowedRoles;
}

const allMenuItems: MenuItem[] = [
  {
    title: 'Admins',
    link: '/admin/admin-users',
    icon: 'account_circle',
    roles: [AdminType.superadmin],
  },
  {
    title: 'Users',
    icon: 'people',
    roles: '*',
    submenu: [
      { title: 'User list', link: '/admin/users' },
      { title: 'Reserved usernames', link: '/admin/reserved-usernames' },
    ]
  },
  {
    title: 'Football',
    icon: 'sports_soccer',
    roles: '*',
    submenu: [
      {
        title: 'Clubs',
        link: '/admin/football/clubs',
      },
      {
        title: 'Fixtures',
        link: '/admin/football/fixtures',
      },
      {
        title: 'Tags',
        link: '/admin/football/tags'
      },
      {
        title: 'Topics',
        link: '/admin/football/topics'
      },
    ],
  },
  {
    title: 'Parleys',
    icon: 'interpreter_mode',
    roles: '*',
    link: '/admin/parleys',
  },
  {
    title: 'Reports',
    icon: 'flag',
    roles: '*',
    link: '/admin/reports',
  },
  {
    title: 'Settings',
    icon: 'settings_suggest',
    roles: [AdminType.superadmin],
    submenu: [
      {
        title: 'Static text copies',
        link: '/admin/settings/static-texts'
      },
      {
        title: 'Parley session',
        link: '/admin/settings/parley-session',
      },
      {
        title: `Club logo replacement`,
        link: '/admin/settings/clubs-logos-replacement-period'
      },
      {
        title: `Profanity`,
        link: '/admin/settings/profanity'
      },
    ]
  },
];

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
  @ViewChildren(MatMenu) menus: QueryList<MatMenu>;

  @Input() isMobile = false;
  @Input() isCondensed = false;

  @Output() menuToggle: EventEmitter<void> = new EventEmitter<void>();
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();

  currentAppVersion: string = '';
  activeLink: string;
  isLoggingOut = false;

  constructor(
    private router: Router,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private toast: ToastService
  ) { }

  ngOnInit() {
    this.currentAppVersion = environment.version;
    this.urlChanged(this.router.url);
  }

  get menuItems() {
    return allMenuItems.filter((item) => item.roles === '*' || item.roles.includes(this.auth.user.type));
  }

  get condensed() {
    return this.isMobile ? false : this.isCondensed;
  }

  urlChanged(url) {
    this.activeLink = url.split('?')[0];
    this.setExpanded();
    this.cdr.detectChanges();
  }

  setExpanded() {
    this.menuItems
      .filter((item) => item.submenu != null)
      .forEach((item) => {
        item.expanded = item.submenu.map((i) => i.link).includes(this.activeLink);
      });
  }

  async onLogout() {
    try {
      this.isLoggingOut = true;
      await this.auth.logout();
      this.logout.emit();
    } catch (error) {
      this.toast.showError(error);
    } finally {
      this.isLoggingOut = false;
    }
  }

  itemClick(item: MenuItem) {
    if (item.submenu && !this.condensed) {
      item.expanded = !item.expanded;
    }
  }
}
