import { FootballTopic } from './football/topic';
import { User } from './user';
import { FootballTag } from './football/tag';
import { BaseModel } from "src/app/shared/models/base-model";

export enum ParticipantStatus {
  // Invitation to Prly session was sent
  pending 				= 'pending',

  // User accepted invitation
  joined 					= 'joined',

  // User rejected invitation
  declined 				= 'declined',

  // Connection to client app was lost; Not disconnected willingly
  lostConnection 	= 'lostConnection',

  // Quits parlay; Disconnected willingly
  disconnected 		= 'disconnected',

  // TBA
  kicked 					= 'kicked',
}

export enum ParleyStatus {
  // ------ Pre / during video call ------
  // Lobby state - host is waiting for all invitees to accept/reject the invitation
  lobby 		= 'lobby',

  // The host cancelled the Parley room when in Lobby mode
  canceled 	= 'canceled',

  // The video call has started
  live 			= 'live',

  // The video call has ended (either manually by host, or time expires)
  ended 		= 'ended',

  // Drafted Prly video - can only be seen by owner
  draft 		= 'draft',

  // Published Prly video
  published = 'published',
}

export interface ParleyParticipant {
	isHost?: boolean;
	user: User;
	status: ParticipantStatus;
  uid?: number;
  invitedAt: Date | null;
  joinedAt: Date | null;
  disconnectedAt: Date | null;
}

export interface Parley extends BaseModel {
  participants: ParleyParticipant[];

  owner: User;
  status: ParleyStatus;

  // Parley data
  title: string;
  tags?: FootballTag[];
  fixture?: any/*FootballFixture*/;
  topic?: FootballTopic;

  duration?: number; // Seconds
  url?: string;
  previewUrl?: string;

  // Needed for Agora
  channelName: string;
}
