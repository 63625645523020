import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";

@Directive({
  selector: '[noWhiteSpaceOnly]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: NoWhiteSpaceOnlyDirective,
    multi: true,
  }],
})
export class NoWhiteSpaceOnlyDirective implements Validator {

  public validate(control: AbstractControl): ValidationErrors | null {
    if (control.value && !control.value.trim().length) {
      return { 'nonWhiteSpaceOnly': true };
    }
    return null;
  }

}
