<h2 mat-dialog-title>Follow request</h2>
<div mat-dialog-content>
    <p>Answer this follow request sent by <b>{{ data.requesterName }}</b> on behalf of <b>{{ data.requestedName }}</b> ?</p>
</div>
<div mat-dialog-actions style="display:flex;justify-content:flex-end;gap:32px;margin-top:16px;margin-bottom:-16px;">
    <button style="margin-right:auto;padding-left:0;text-align:left;" mat-flat-button (click)="dialogRef.close(null)">Cancel</button>
    <button mat-icon-button
            class="btn-primary"
            (click)="dialogRef.close(false)"
            matTooltip="Reject">

        <i class="icon">close</i>
    </button>
    <button mat-icon-button
            class="btn-primary"
            (click)="dialogRef.close(true)"
            matTooltip="Accept">

        <i class="icon">done</i>
    </button>
</div>
