import { Pipe, PipeTransform } from '@angular/core';
import { TextUtils } from 'src/app/shared/utils/text.utils';

@Pipe({
  name: 'textHumanize',
})
export class TextHumanizePipe implements PipeTransform {
  transform(value: any, type?: 'secondsFormat' | string): any {
    if (!type?.length) return TextUtils.textHumanize(value)

    switch (type) {
      case 'secondsFormat': {
        const seconds = value as number;
        const m = Math.floor(seconds / 60);
        const s = Math.floor(seconds % 60);
        return `${ m } minute${ m > 1 ? 's' : ''} and ${ s } second${ s > 1 ? 's' : '' }`;
      }
      default:
        throw new Error(`Invalid type (${ type })`);
    }
  }
}
