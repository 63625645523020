import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent {
  title: string;
  message: string;
  yesBtnText: string;
  noBtnText: string;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.title = (data || { }).title || 'Attention';
    this.message = (data || { }).message || 'Are you sure you want to perform this action?';
    this.yesBtnText = (data || { }).yesBtnText || 'Yes';
    this.noBtnText = (data || { }).noBtnText || 'Cancel';
  }
}
