<section>
  <img src="/assets/images/logo-prly.svg" alt="Logo" />

  <!-- <h1>Log in to admin</h1> -->

  <div class="form-group">
    <label for="email">Email</label>
    <input
      [(ngModel)]="email"
      type="email"
      id="email"
      name="email"
      (keyup.enter)="login()"
      placeholder="Enter your email"
      autocomplete="email"
    />
  </div>

  <div class="form-group">
    <label for="password">Password</label>
    <input
      [(ngModel)]="password"
      type="password"
      id="password"
      name="password"
      autocomplete="current-password"
      (keyup.enter)="login()"
      placeholder="Enter your password"
    />
  </div>

  <button mat-flat-button class="bg-primary" (click)="login()" [appLoading]="isLoading">Login</button>

  <app-error [error]="error"></app-error>
</section>
