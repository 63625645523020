import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.service';
import { Admin } from '../../shared/models/admin';

@Injectable({
  providedIn: 'root',
})
export class AdminUserService extends CrudService<Admin> {
  constructor(http: HttpClient) {
    super(http, 'admin-list');
  }

  public async findOne(adminId: string): Promise<Admin> {
    const { admin } = await this.http
      .get<{ admin: Admin }>(`${ this.endpoint }/${ adminId }`)
      .toPromise();

    return admin;
  }

  public async updateOne(id: string, data: Readonly<Partial<Admin>>): Promise<Admin> {
    console.log({ id, data });
    const filteredData: Partial<Admin> = { };
    Object.keys(data)
      .filter((key) => !['_id', 'createdAt', 'updatedAt', '__v'].includes(key))
      .forEach((key) => filteredData[key] = data[key]);

    const result = await super.updateOne(id, filteredData);
    return (result as any).admin;
  }
}
