<header class="page-header">
  <h1>Admins</h1>

  <div class="header-tools">
    <button mat-flat-button class="btn-primary" routerLink="add"><i class="icon">add</i> Add admin</button>
  </div>
</header>

<app-filters queryParams="type,status">
  <div class="form-group">
    <label>Status</label>
    <app-select-filter field="status" [options]="userStatuses" placeholder="User status"></app-select-filter>
  </div>
  <div class="form-group">
    <label>Role</label>
    <app-select-filter field="type" [options]="userRoles" placeholder="User role"></app-select-filter>
  </div>
  <div *ngIf="activeFilters?.length" class="form-group">
    <label>&nbsp;</label> <!-- Layout consistency -->
    <button mat-icon-button
            matTooltip="Clear filters"
            matTooltipPosition="below"
            (click)="onClearFilters()">
      <span class="material-icons-outlined">
        delete_sweep
      </span>
    </button>
  </div>
</app-filters>

<app-error [error]="error"></app-error>

<section class="table-wrapper" [appLoading]="isLoading">
  <div class="table-container">
    <table mat-table matSort [dataSource]="data" [matSortActive]="activeSort" [matSortDirection]="activeSortDirection">
      <ng-container matColumnDef="joinDate">
        <th mat-header-cell *matHeaderCellDef class="cell-center" mat-sort-header>Account created</th>
        <td mat-cell *matCellDef="let row">{{ row.createdAt | date: 'medium' }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let row">{{ row.email }}</td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let row">{{ row.firstName || '' }}{{ !row.lastName ? '' : ' ' + row.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="cell-center">Status</th>
        <td mat-cell *matCellDef="let row; let i = index" class="cell-center">
          <app-badge [color]="statusBadgeColor(row)">{{ row.status }}</app-badge>
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="cell-center">Type</th>
        <td mat-cell *matCellDef="let row; let i = index" class="cell-center">
          <app-badge [color]="roleBadgeColor(row)">{{ row.type }}</app-badge>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef class="cell-center">Actions</th>
        <td mat-cell *matCellDef="let row" (click)="$event.stopPropagation()" class="cell-center">
          <button *ngIf="row._id !== authService.user._id"
                  mat-icon-button matTooltip="Delete user"
                  (click)="deleteUser(row._id)"
                  color="warn"
          >
            <i class="icon">delete</i>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns; let i = index"
        [routerLink]="['/admin/admin-users', row._id]"
      ></tr>
      <tr class="mat-row empty-table-row" *matNoDataRow>
        <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data available in table</td>
      </tr>
    </table>
  </div>

  <mat-paginator
    [length]="dataLength"
    [pageIndex]="activePage"
    [pageSize]="activeLength"
    [pageSizeOptions]="pageSizeOptions"
    showFirstLastButtons
  >
  </mat-paginator>
</section>
