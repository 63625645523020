import { Pipe, PipeTransform } from '@angular/core';
import { TextUtils } from '../utils/text.utils';

@Pipe({
  name: 'capitalize'
})
export class CapitalizePipe implements PipeTransform {

  transform(text: string, allWords: boolean = false): unknown {
    return TextUtils.capitalize(text, allWords);
  }

}
