import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TextUtils } from 'src/app/shared/utils/text.utils';
import { DataTableComponent } from '../../../shared/components/data-table.component';
import { Admin, AdminStatus, AdminType } from '../../../shared/models/admin';
import { UserStatus } from '../../../shared/models/user';
import { AdminUserService } from '../../../shared/services/admin-user.service';
import { AuthService } from '../../../shared/services/auth.service';
import { ConfirmationService } from '../../../shared/services/confirmation.service';
import { ToastService } from '../../../shared/services/toast.service';

@UntilDestroy()
@Component({
  selector: 'app-admin-user-table',
  templateUrl: './admin-user-table.component.html',
  styleUrls: ['./admin-user-table.component.scss'],
})
export class AdminUserTableComponent extends DataTableComponent<Admin> implements OnInit {
  displayedColumns = ['name', 'email', 'type', 'status', 'joinDate', 'actions'];

  userStatuses = Object.values(UserStatus).map((item) => ({
    label: TextUtils.capitalize(item),
    value: item,
  }));
  userRoles = Object.values(AdminType).map((item) => ({
    label: TextUtils.capitalize(item),
    value: item,
  }));

  activeFilters: string[] = [];

  constructor(
    public authService: AuthService,
    protected adminUserService: AdminUserService,
    protected confirmationService: ConfirmationService,
    protected toast: ToastService,
    protected router: Router,
    protected route: ActivatedRoute,
  ) {
    super(adminUserService);
  }

  public ngOnInit() {
    super.ngOnInit();

    this.adminUserService
      .dataChanged$()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.reloadData());

    this.route
      .queryParamMap
      .pipe(untilDestroyed(this))
      .subscribe((paramMap) =>
        this.activeFilters = paramMap.keys
          .map((key) => {
            const elem = paramMap.get(key);
            return elem == null ? null : key;
          })
          .filter((elem: string | null) => elem != null)
      );
  }

  public deleteUser(userId) {
    this.confirmationService.confirm({
      onSuccess: async () => {
        this.isLoading = true;
        try {
          await this.adminUserService.deleteOne(userId);
          await this.toast.show('Admin user deleted');
        } catch (error) {
          this.error = error;
        }
        this.isLoading = false;
      },
    });
  }

  public onClearFilters() {
    return this.router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: this.activeFilters
        .map((key) => ({ [key]: null }))
        .reduce((result, crt) => ({ ...result, ...crt }), { }),
    });
  }

  public statusBadgeColor({ status }: Admin) {
    switch (status) {
      case AdminStatus.active: return 'success';
      case AdminStatus.pending: return 'accent';
      case AdminStatus.suspended: return 'danger';
      default: throw new Error('Invalid admin status');
    }
  }

  public roleBadgeColor({ type }: Admin) {
    switch (type) {
      case AdminType.superadmin: return 'success';
      case AdminType.admin: return 'accent';
      default: throw new Error('Invalid role');
    }
  }
}
