import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Directive, Injector, OnInit, ViewChild } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { merge } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppInjector } from 'src/app/app.module';
import { PagedResponse } from '../models/paged-response';
import { TableQueryParams } from '../models/table-query-params';
import { CrudService } from '../services/crud.service';

import { TableUtils } from '../utils/table.utils';

@UntilDestroy()
@Directive()
export abstract class DataTableComponent<T> implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('masterToggleCheckbox') masterToggleCheckbox: MatCheckbox;

  data: any[];
  dataLength = 0;
  isLoading = false;
  error: any;

  activeSort;
  activeSortDirection;
  activePage = 0;
  activeLength = 25;
  pageSizeOptions = [10, 25, 50, 100];

  queryParams: ParamMap;
  displayedColumns: string[];

  selection = new SelectionModel<string>(true, []);
  isAllFromPageSelected = false;

  protected router: Router;
  protected route: ActivatedRoute;

  constructor(private service: CrudService<T>) {
    this.router = AppInjector.get(Router);
    this.route = AppInjector.get(ActivatedRoute);
  }

  ngOnInit() {
    this.route.queryParamMap.pipe(untilDestroyed(this)).subscribe((params) => {
      this.queryParams = params;
      this.reloadData();
    });
  }

  ngAfterViewInit() {
    merge(this.paginator.page, this.sort.sortChange)
      .pipe(
        tap(async () => {
          this.setPagingParams();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  async setMasterToggleState() {
    if (!this.masterToggleCheckbox) return;
    const pageIds = this.data.map((user) => user._id);
    let intersect = false;
    let containsAllFromPage = true;
    for (const id of pageIds) {
      if (this.selection.isSelected(id)) {
        intersect = true;
      } else {
        containsAllFromPage = false;
      }
    }
    this.masterToggleCheckbox.checked = containsAllFromPage;
    this.masterToggleCheckbox.indeterminate = intersect && !containsAllFromPage;
    this.isAllFromPageSelected = containsAllFromPage;
  }

  async setPagingParams() {
    const queryParams = {
      page: this.paginator.pageIndex,
      length: this.paginator.pageSize,
      sortOrder: this.sort.direction,
      column: this.sort.active,
    };
    if (!queryParams.sortOrder) queryParams.column = '';
    await this.setQueryParams(queryParams);
  }

  async setQueryParams(queryParams) {
    await this.router.navigate([], {
      relativeTo: this.route,
      queryParams,
      queryParamsHandling: 'merge',
    });
  }

  async reloadData() {
    const params = this.queryParams;
    const queryParams = TableUtils.parseParams(
      params,
      this.activePage,
      this.activeLength,
      this.activeSort,
      this.activeSortDirection
    );

    this.activePage = queryParams.page;
    this.activeLength = queryParams.length;
    TableUtils.setSortColumn(this.sort, this.activeSort);

    try {
      this.isLoading = true;
      const data = await this.fetchData(queryParams);
      this.data = data.data;
      this.dataLength = data.total;
      this.setMasterToggleState();
    } catch (error) {
      this.error = error;
    } finally {
      this.isLoading = false;
    }
  }

  fetchData(params: TableQueryParams): Promise<PagedResponse<T>> {
    return this.service.getAll(params);
  }
}
