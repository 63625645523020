import { NgModule } from '@angular/core';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminUsersModule } from './admin-users/admin-users.module';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [],
  imports: [SharedModule, AdminRoutingModule, AdminUsersModule],
})
export class AdminModule {}
