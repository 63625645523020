import { BaseModel } from './base-model';
import { FootballClub } from './football/club';
import { UploadScope } from './upload';

export enum UserType {
  admin = 'admin',
  user = 'user',
}

export enum UserStatus {
  pending = 'pending',
  active = 'active',
  suspended = 'suspended',
}

interface DeviceInformation {
  appVersion: string;
  osVersion: string;
  deviceId: string;
}

export interface User extends BaseModel {
  firstName?: string;
  lastName?: string;
  nickname?: string;
  isFullNameDisplayed: boolean;
  displayName: string;

  status: UserStatus;
  timezone: string;
  phoneNumber: string;

  profilePicture?: string; // URL
  mainClub?: FootballClub;

  follows: {
    followers: number;
    following: {
      users: number;
      clubs: number;
    };
  }

  // App version, os, deviceId (of mobile apps)
  deviceData?: DeviceInformation;

  // Total count of all owned Parleys' durations
  minsCreated?: number;

  email: string | null;
  isEmailUpdatesEnabled: boolean;
}
