import { Pipe, PipeTransform } from '@angular/core';
import { ParleyStatus } from "src/app/shared/models/parley";

@Pipe({
  name: 'parleyStatus'
})
export class ParleyStatusPipe implements PipeTransform {

  transform(status: ParleyStatus): string {
    switch (status) {
      case ParleyStatus.lobby: return 'Lobby';
      case ParleyStatus.canceled: return 'Canceled by host';
      case ParleyStatus.live: return 'Ongoing / Live';
      case ParleyStatus.ended: return 'Just ended';
      case ParleyStatus.draft: return 'Saved as draft';
      case ParleyStatus.published: return 'Published';
      default: throw new Error(`Unhandled status (${ status })`);
    }
  }

}
