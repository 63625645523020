<header *ngIf="isMobile && isOnAdmin">
  <app-toolbar (menuToggle)="toggleSidenav()"></app-toolbar>
</header>

<div class="backdrop" *ngIf="isMobile && sidenavOpened"></div>

<aside *ngIf="isOnAdmin || isMobile" [class.opened]="sidenavOpened" (click)="toggleSidenav()">
  <app-sidenav
    [isMobile]="isMobile"
    [isCondensed]="condensed"
    (menuToggle)="toggleSidenav()"
    (logout)="sidenavOpened = false"
    (click)="$event.stopPropagation()"
  ></app-sidenav>
</aside>

<main>
  <router-outlet></router-outlet>
</main>
