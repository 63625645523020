import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../shared/guards/auth.guard';
import { RoleGuard } from '../shared/guards/role.guard';
import { AdminType } from '../shared/models/admin';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: '*' },
        loadChildren: () => import('./user/user.module').then(m => m.UserModule),
      },
      {
        path: 'admin-users',
        canActivate: [RoleGuard],
        data: { role: [AdminType.superadmin] },
        loadChildren: () => import('./admin-users/admin-users.module').then(m => m.AdminUsersModule),
      },
      {
        path: 'football',
        canActivate: [RoleGuard],
        data: { role: '*' },
        loadChildren: () => import('./football/football.module').then(m => m.FootballModule),
      },
      {
        path: 'parleys',
        canActivate: [RoleGuard],
        data: { role: '*' },
        loadChildren: () => import('./parleys/parleys.module').then(m => m.ParleysModule),
      },
      {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: '*' },
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
      },
      {
        path: 'reserved-usernames',
        canActivate: [RoleGuard],
        data: { role: '*' },
        loadChildren: () => import('./reserved-nicknames/reserved-nicknames.module').then(m => m.ReservedNicknamesModule),
      },
      {
        path: 'settings',
        canActivate: [RoleGuard],
        data: { role: [AdminType.superadmin] },
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdminRoutingModule {}
