import { Pipe, PipeTransform } from '@angular/core';
import { User } from '../models/user';
import { TextUtils } from '../utils/text.utils';

@Pipe({
  name: 'displayName'
})
export class DisplayNamePipe implements PipeTransform {

  transform(user: undefined | null | User): string {
    return user == null
      ? ''
      : TextUtils.userNameDisplay(user);
  }

}
