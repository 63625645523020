import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { take } from 'rxjs/operators';

interface ConfirmParams {
  onSuccess: () => void;
  onDismiss?: () => void;
  message?: string;
  title?: string;
  yesBtnText?: string;
  noBtnText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  constructor(private dialog: MatDialog) {}

  confirm({ onSuccess, onDismiss, message, title, yesBtnText, noBtnText }: ConfirmParams) {
    const config: MatDialogConfig = {
      autoFocus: false,
    };
    if (message || title) {
      config.data = { message, title };
    }
    const dialogRef = this.dialog
      .open(ConfirmationDialogComponent, {
        autoFocus: false,
        panelClass: 'confirmation-dialog-component',
        data: {
          ...(message?.length && { message }),
          ...(title?.length && { title }),
          ...(yesBtnText?.length && { yesBtnText }),
          ...(noBtnText?.length && { noBtnText }),
        }
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe(async (resp) => {
        if (resp) onSuccess();
        else if (onDismiss) onDismiss();
      });
  }
}
