import * as dayjs from 'dayjs';
import * as dayjsUtc from 'dayjs/plugin/utc';
import * as dayjsTimezone from 'dayjs/plugin/timezone';

dayjs.extend(dayjsUtc);
dayjs.extend(dayjsTimezone);

export class TimeUtils {

  public static get timezone() {
    return dayjs.tz.guess();
  }

  // date1 is past date2
  public static isPast(date1: Date, date2: Date) {
    return dayjs(date1).isAfter(dayjs(date2));
  }
}
